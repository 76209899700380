import React, { Suspense } from "react";
import { Oval } from "react-loader-spinner";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import analytics from "./analytics/analytics";
import "./App.css";
import { useQueryParams } from "./hooks/useQueryParams";
import BackButton from "./journey-list/assets/images/back-button.png";
import { BackButtonImage } from "./journey-map/JourneyMap.styled";
const JourneyList = React.lazy(() => import("./journey-list/JourneyList"));
const JourneyMap = React.lazy(() => import("./journey-map/JourneyMap"));

const LazyComponent: React.FC<{ element: React.ReactNode; showBackButton: boolean }> = ({
  element,
  showBackButton,
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams(),
    journeyId = parseInt(queryParams.get("journeyId") || "1");
  return (
    <Suspense
      fallback={
        <div>
          {showBackButton ? (
            <BackButtonImage
              onClick={() => {
                analytics.log("learning_journey_map_back_button_click", {
                  "Screen Name": "Learning Journey Map",
                  journey_id: journeyId,
                });
                console.log("journeyMapExited--true");
                navigate(-1);
              }}
              style={{ position: "absolute", width: "8%", padding: "2%", zIndex: 110 }}
              src={BackButton}
            />
          ) : (
            ""
          )}
          <div
            style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: "100" }}
          >
            <Oval height={50} width={50} color="orange" strokeWidth={4} />
          </div>
        </div>
      }
    >
      {element}
    </Suspense>
    // <Suspense fallback={<div />}>{element}</Suspense>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LazyComponent showBackButton={false} element={<JourneyList />} />} />
        <Route path="/map" element={<LazyComponent showBackButton={true} element={<JourneyMap />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
