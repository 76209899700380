import Player from "react-lottie-player";
import styled from "styled-components";

export const slideTransition = `
@keyframes mapSlide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
`;

export const PageContainer = styled("div")<{ transitioning: boolean }>`
  ${slideTransition}
  animation: ${(props) => (props.transitioning ? "mapSlide .4s ease-in-out" : "none")};
`;

export const BackButtonImage = styled("img")`
  position: absolute;
  width: 8%;
  padding: 2%;
`;

export const JourneyMapContainer = styled("div")`
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  position: absolute;
  inset: 0;
`;

export const widthFactor = (aspectRatio: number): number => {
  const screenAspectRatio = window.innerWidth / window.innerHeight;
  return (aspectRatio * 100) / screenAspectRatio;
};

export const MapSectionContainer = styled("div")<{
  aspectRatio: number;
  marginFactor: number;
}>`
  height: 100%;
  margin-left: ${(props) => `${widthFactor(props.aspectRatio) * props.marginFactor}%`};
`;

export const LottiePlayerContainer = styled(Player)<{ aspectRatio: number }>`
  position: absolute;
  width: ${(props) => `${widthFactor(props.aspectRatio)}%`};
  height: 100%;
`;

export const MapItemsContainer = styled("div")<{ aspectRatio: number }>`
  position: absolute;
  width: ${(props) => `${widthFactor(props.aspectRatio)}%`};
  height: 100%;
`;

export const BannerImage = styled("div")`
  position: absolute;
  text-align: center;
  top: 1rem;
  border-radius: 5vh;
  padding: 0.1% 5vh 0.3% 5vh;
  color: white;
  font-family: "Arial";
  font-weight: 700;
  font-size: 7vh;
`;

export const MissionCompleteImage = styled("img")`
  @keyframes missionZoom {
    0% {
      transform: scale(0);
    }
    10% {
      transform: scale(1);
    }
    90% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  height: 80%;
  transform: scale(0);
  animation: missionZoom 4s ease-in-out;
`;
