import mixpanel from "mixpanel-browser";

let appId = new URLSearchParams(window.location.search).get("appid");
if(!appId){
  appId = new URLSearchParams(window.location.search).get("appId");
}
mixpanel.init(
  (appId === "2" ? process.env.REACT_APP_TN_MIXPANEL_API_KEY : process.env.REACT_APP_LS_MIXPANEL_API_KEY) || "",
  { debug: true }
);

const analytics = {
  log: (eventName: string, properties: { [key: string]: any }) => mixpanel.track(eventName, properties),
};

export default analytics;
